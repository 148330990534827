import { Route, Routes } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { Authenticator, View, Image, useTheme, Text } from '@aws-amplify/ui-react';
import awsExports from './aws-exports'


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';

import SiteNav from './components/common/SiteNav';
import SiteFooter from './components/common/SiteFooter';

import HomePage from './components/home/HomePage';
// import LoginPage from './components/auth/LoginPage';
// import RegisterPage from './components/auth/RegisterPage';
import PortfolioPage from './components/portfolio/PortfolioPage';

Amplify.configure(awsExports);

function App() {
  const components = {
    Header() {
        const { tokens } = useTheme();
    
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Image width={300} height={150}
              alt="bnbdojo logo"
              // src="https://docs.amplify.aws/assets/logo-dark.svg"
              src="/img/logo1.png"
            />
          </View>
        );
      },

      Footer() {
        const { tokens } = useTheme();
    
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              &copy; 2023 bnbdojo
            </Text>
          </View>
        );
      },
    };

    
  return (
    <Authenticator loginMechanism={['email']} components={components}>
      {({ signOut, user }) => (
        <div>
        <SiteNav logOut={signOut}/>
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/" exact={true} element={<HomePage />} />
          {/* <Route path="/login" exact={true} element={<LoginPage />} />
          <Route path="/register" exact={true} element={<RegisterPage />} /> */}
          <Route path="/portfolio" exact={true} element={<PortfolioPage />} />
        </Routes>
        <SiteFooter />
      </div>
      )}
    </Authenticator>
  );
}

export default App;
