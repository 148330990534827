import Container from "react-bootstrap/Container";
import Table from 'react-bootstrap/Table';

function PortfolioPage() {
    return(
        <Container>
            <Table responsive="md">
                <thead>
                <tr>
                    <th>Type</th>
                    <th>Symbol</th>
                    <th>Status</th>
                    <th>Qty</th>
                    <th>Buy at</th>
                    <th>Sell at</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Stock</td>
                    <td>APPL</td>
                    <td>CLOSED</td>
                    <td>10</td>
                    <td>90</td>
                    <td>120</td>
                </tr>
                <tr>
                    <td>Options</td>
                    <td>SHOP 80 CE</td>
                    <td>OPEN</td>
                    <td>-1</td>
                    <td>0</td>
                    <td>120</td>
                </tr>
                <tr>
                    <td>Stock</td>
                    <td>MSFT</td>
                    <td>OPEN</td>
                    <td>10</td>
                    <td>120</td>
                    <td>0</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    )
}

export default PortfolioPage;