import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

function HomePage () {
    return(
        <Container>
            <Row className="px-4 my-5">
                <Col xs={4} sm={6}>
                    <Image src="/img/logo.png" fluid />
                </Col>
                <Col sm={6}>
                    <h1 className="font-weight-light">BnB Dojo</h1>
                    <p className="mt-4">
                        Lorem zamzam
                        <br /><br />
                        Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text
                    </p>
                    <Link to={{pathname: '/portfolio'}}>
                        <Button varaint="outline-primary">View my portfolio &gt;&gt;</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage; 